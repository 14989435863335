import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { FC, useEffect, useState } from "react";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import useProperty from "hooks/properties/useProperty";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import getPricePerNight from "utils/GetCurrentPrice";
import getTotalNights from "utils/getTotalNights";
import convertNumbThousand from "utils/convertNumbThousand";
import FormCheckOut from "./FormCheckOut";
import usePayment, { PaymentProperty } from "hooks/usePayment";
import { Token } from "@stripe/stripe-js";
import useUserStore from "stores/userStore";
import formatDate from "utils/formatDate";
import Swal from "sweetalert2";
import useFilterStore from "stores/FilterStore";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const infants = searchParams.get("infants");
  const { data: property } = useProperty(id!);
  const [PricePerNight, setGetPricePerNight] = useState({
    Number: 0,
    Text: "",
  });
  const [totalNights, setTotalNights] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const { check_in, check_out, setFilter } = useFilterStore();

  const handleSelectDate = (
    startDate: Date | null,
    endDate: Date | null
  ): void => {
    if (startDate && endDate) {
      setFilter("check_in", startDate);
      setFilter("check_out", endDate);
    }
  };

  useEffect(() => {
    if (check_in && check_out && property) {
      const pricePerNight = getPricePerNight(
        property.rates,
        check_in,
        check_out
      );

      setGetPricePerNight(pricePerNight);
      setTotalNights(getTotalNights(new Date(check_in), new Date(check_out)));
      // service charge is the 2.5% of the total price
      setServiceCharge(pricePerNight.Number * totalNights * 0.025);
    }
  }, [check_in, check_out, id, property, totalNights]);

  const { user } = useUserStore();
  const { mutateAsync } = usePayment(() => {});

  const handleSuccessfulPayment = async (token: Token) => {
    if (!property || !check_in || !check_out || !user) return;
    const PostInfo: PaymentProperty = {
      token: token.id,
      property_id: property.property_id,
      start_date: formatDate(check_in!),
      end_date: formatDate(check_out!),
      user_id: user.user_id,
      guests: adults ? adults : "0",
      children: children ? children : "0",
      infants: infants ? infants : "0",
      total_price: PricePerNight.Number * totalNights + serviceCharge,
      currency: user.currency ? user.currency : "usd",
      date_created: formatDate(new Date()),
    };
    console.log(PostInfo);

    try {
      await mutateAsync(PostInfo);
      Swal.fire({
        icon: "success",
        title: "Payment successful",
        text: "Your payment has been successfully processed",
      });

      navigate("/pay-done");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Payment failed",
        text: `An error occurred: ${error}`,
      });
    }
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={property?.images[0].image_url}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {`${property?.address}, ${property?.city}`}
              </span>
              <span className="text-base font-medium mt-1 block">
                {property?.title}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {property?.number_of_bedrooms} beds · {property?.number_of_baths}{" "}
              baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {PricePerNight.Text} x {totalNights} day
            </span>
            <span>
              {" "}
              $
              {property &&
                convertNumbThousand(PricePerNight.Number * totalNights)}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>${convertNumbThousand(serviceCharge)}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              $
              {convertNumbThousand(
                PricePerNight.Number * totalNights + serviceCharge
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your trip</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              defaultStartDate={check_in}
              defaultEndDate={check_out}
              handleSelectDate={handleSelectDate}
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString([
                        check_in ? new Date(check_in) : new Date(),
                        check_out ? new Date(check_out) : new Date(),
                      ])}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${adults !== "0" ? adults + " guest" : ""}  ${
                          children !== "0" ? children + " children" : ""
                        }  ${infants !== "0" ? infants + " guests" : ""} `}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <FormCheckOut onSuccessfulPayment={handleSuccessfulPayment} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
