import { Currency, Rate } from "data/types";
import convertNumbThousand from "./convertNumbThousand";
import { toInteger } from "lodash";

function getPricePerNight(
  rates: Rate[],
  startDate: Date,
  endDate: Date
): { Number: number; Text: string } {
  // Convert dates to a simpler format (only date part, no time)
  const start = new Date(startDate.toISOString().split("T")[0]);
  const end = new Date(endDate.toISOString().split("T")[0]);

  // Initialize variables to accumulate total price and count days
  let totalPrice = 0;
  let totalDays = 0;
  let currency: Currency | null = null;

  // Loop through each date in the range
  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const currentDate = new Date(d);

    for (const rate of rates) {
      const startDate = new Date(rate.season.start_date);
      const endDate = new Date(rate.season.end_date);
      if (endDate < startDate) {
        // Temporada abarca el fin de año
        if (currentDate >= startDate || currentDate <= endDate) {
          totalPrice += rate.price_per_night;
          totalDays += 1;
          if (!currency) {
            currency = rate.currency;
          }
        }
      } else if (currentDate >= startDate && currentDate <= endDate) {
        totalPrice += rate.price_per_night;
        totalDays += 1;
        if (!currency) {
          currency = rate.currency;
        }
      }
    }
  }

  // Calculate the average price per night
  const averagePricePerNight = totalDays > 0 ? totalPrice / totalDays : 0;

  // Return the formatted price with currency
  return {
    Number: toInteger(averagePricePerNight),
    Text: `${convertNumbThousand(averagePricePerNight)} ${currency}`,
  };
}

export default getPricePerNight;
