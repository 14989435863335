import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useGetWishlist from "hooks/users/useGetWishlist";
import React from "react";
import MyRouter from "routers/index";
import useUserStore from "stores/userStore";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function App() {
  const stripePromise = loadStripe(
    "pk_test_51PludnLAJoUTgXoBZdj1McJ65G9DxQ998rVdgzExhJVn7KTwBxgjqwOAYmB6WCCDhAc2sGk0N0NDSjK9Z8FoWz9y00wZMujuhk"
  );
  const {
    setUser,
    setIsLogged,
    user: { user_id },
    setUserWishlist,
  } = useUserStore();
  const { data } = useGetWishlist(user_id);
  React.useEffect(() => {
    const user = localStorage.getItem("User");
    if (user) {
      setUser(JSON.parse(user));
      setIsLogged(true);

      if (data) {
        const wishlist = data.map((property: any) => property.property_id);
        setUserWishlist(wishlist);
      }
    }
  }, [setUser, data]);

  return (
    <GoogleOAuthProvider clientId="752729360207-9e9afkpdinf5rsht6l1dmdluj673dcrd.apps.googleusercontent.com">
      <Elements stripe={stripePromise}>
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
          <MyRouter />
        </div>
      </Elements>
      {/* <ReactQueryDevtools /> */}
    </GoogleOAuthProvider>
  );
}

export default App;
