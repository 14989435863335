import ButtonPrimary from "shared/Button/ButtonPrimary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FormEvent, useState } from "react";
import { StripeCardNumberElement, Token } from "@stripe/stripe-js";

interface FormCheckOutProps {
  onSuccessfulPayment: (token: Token) => void;
}

const FormCheckOut: React.FC<FormCheckOutProps> = ({ onSuccessfulPayment }) => {
  const [cardName, setCardName] = useState<string>("");
  const [zipNumber, setZipNumber] = useState<string>("");
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const GenerateStripeToken = async () => {
    if (!stripe || !elements) return;

    const cardNumber = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(
      cardNumber as StripeCardNumberElement,
      {
        name: cardName,
        address_zip: zipNumber,
      }
    );

    if (!token || error) {
      console.error(error);
      setFormErrorMessage(
        error?.message ??
          "An error occurred while processing your payment. Please try again."
      );

      return;
    }

    return token;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);

    setFormErrorMessage(null);

    if (cardName === "") {
      setFormErrorMessage("Card Name is required");
      return;
    }

    if (zipNumber === "") {
      setFormErrorMessage("Zip Code is required");
      return;
    }

    try {
      const token = await GenerateStripeToken();
      if (token) {
        onSuccessfulPayment(token);
      }
      setIsProcessing(false);
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-row">
        <div className="w-full mr-2">
          <Label>Name</Label>
          <Input
            placeholder="Card Name"
            className="h-11"
            name="firstName"
            type="text"
            onChange={(e) => setCardName(e.target.value)}
          />
        </div>
        <div className="">
          <Label>Zip Code</Label>
          <Input
            placeholder="Zip Code"
            className="h-11"
            name="ZipCode"
            type="number"
            onChange={(e) => setZipNumber(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-3">
        <Label>Card Number</Label>
        <CardNumberElement className="block border w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 " />
      </div>

      {/* dos input en una fila */}
      <div className="grid grid-cols-2 gap-3 md:grid-cols-3">
        <div>
          <Label>Expiry</Label>
          <CardExpiryElement className="block border w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 " />
        </div>
        <div>
          <Label>CVC</Label>
          <CardCvcElement className="block border w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 " />
        </div>
      </div>
      {formErrorMessage && (
        <p className="text-red-500 mt-3 p-3">{formErrorMessage}</p>
      )}
      <div>
        <ButtonPrimary className="mt-3" type="submit">
          {isProcessing ? "Processing..." : "Confirm and Payment"}
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default FormCheckOut;
